<template>
    <div class="sup_content language-list">
        <div class="sup_page_heading">
            <h2>Language Text</h2>
        </div>
        <div class="sup_inner_content">
            <!--- Show Language Text list Start --->
            <DataTable class="p-datatable" :paginator="true" :value="allLangageTextData.data" :rows="10" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {currentPage} of {totalPages}" :rowsPerPageOptions="[10,20,50]" :rowHover="true" :loading="allLangageTextData.loading" responsiveLayout="scroll" removableSort :filters="filters">
                <template #loading>
                    Loading languages data. Please wait.
                </template>
                <template #header>
                    <div class="p-d-flex p-jc-between">
                        <div class="sup_list_action">
                            <Button :label="allLangageTextData.activityStatus === 'active' ? 'Deactive List': 'Active List'" :icon="['pi', allLangageTextData.activityStatus === 'active' ? 'pi-ban' : 'pi-check-circle']" :class="[allLangageTextData.activityStatus === 'active' ? 'p-button-danger' : 'p-button-primary', 'p-mr-2']" @click="fetchAllLanguageText(allLangageTextData.activityStatus, 'languageStatus')" />
                        </div>
                        <div class="p-d-flex p-align-center">
                            <span class="p-input-icon-left sup_search_box">
                                <i class="pi pi-search" />
                                <InputText v-model="allLangageTextData.search" placeholder="Search Language Text" @keyup.enter="fetchAllLanguageText(allLangageTextData.activityStatus, 'search')"/>
                            </span>
                            <Calendar v-model="allLangageTextData.dateRange" placeholder="DD.MM.YY" dateFormat="dd.mm.yy" selectionMode="range" class="sup_button p-ml-2" inputClass="p-inputtext" :manualInput="false" :showIcon="true" @date-select="allLangageTextData.dateRange[1] != null ? fetchAllLanguageText(allLangageTextData.activityStatus, 'dateRange') : ''" plceholder="abc" />
                            <Button v-if="allLangageTextData.search !== null || allLangageTextData.dateRange !== null" icon="pi pi-refresh" class="p-button-sm p-button-dark sup_search_clear p-ml-2" @click="fetchAllLanguageText(allLangageTextData.activityStatus, 'resetFilters')"/>
                            <SplitButton label="Export" icon="pi pi-external-link" class="p-button-secondary p-ml-2" :model="exportType" @click="exportXL('excel')"></SplitButton>
                        </div>
                    </div>
                </template>
                <template #empty>
                    No Language found...
                </template>
                <Column field="keyName" header="Language Text" :sortable="true">
                    <template #body="slotProps">
                        <strong>{{capitalizeCase(slotProps.data.keyName)}}</strong>
                    </template>
                </Column>

                <Column field="createdAt" header="Created On">
                    <template #body="slotProps">
                        {{usFormatDate(slotProps.data.createdAt)}}
                    </template>
                </Column>

                <Column field="status" header="Status">
                </Column>

                <Column field="action" header="Actions">
                    <template #body="slotProps">
                        <Button type="button" icon="pi pi-pencil" class="p-button-sm p-button-rounded p-button-warning p-mr-1 sup-button-svg-icon" @click="traslateLanguage(slotProps.data)" v-tooltip="'Translation'">
                            <img class="sup_folder_icon" src="../../../../assets/images/translation_icon.svg" alt="Folder">
                        </Button>
                        <Button v-if="slotProps.data.deletedAt === null" type="button" icon="pi pi-ban" class="p-button-sm p-button-rounded p-button-danger p-mr-1" v-tooltip="'Deactivate'" @click="confirmationModalfn(slotProps.data, 'delete')" />
                        <Button v-if="slotProps.data.deletedAt !== null" icon="pi pi-undo" class="p-button-secondary p-button-rounded p-button-sm p-mr-1"  v-tooltip="'Restore Language'" @click="confirmationModalfn(slotProps.data, 'restore')" />
                        <Button v-if="slotProps.data.deletedAt !== null" icon="pi pi-trash" class="p-button-danger p-button-rounded p-button-sm"  v-tooltip="'Delete Language'" />
                    </template>
                </Column>
            </DataTable>
            <!--- Show Language Text list End --->
        </div>
        <!--- Edit Language Key Modal Start --->
        <Dialog header="Translated text" v-model:visible="languageTextModals.translation" :modal="true" :style="{width: '800px'}">
            <div class="sup_translation_wrap">
                <div v-for="(translationSlot, i) in languageTextModals.translationData" :key="translationSlot.translateduuid" class="sup_translation_col">
                    <div class="sup_language_edit" @click="editlanguage(i, translationSlot.translatedText)" v-tooltip="'Edit language'"><i :class="['pi', editLanguageState === i ? 'pi-check' : 'pi-pencil']"></i></div>
                    <div :class="['sup_language_edit_area', editLanguageState === i ? 'sup_show_edit' : 'sup_hide_edit']">
                        <form class="sup_language_update" @submit.prevent="updateLanguageValue(translationSlot.translateduuid)">
                            <div class="sup_language_name">{{translationSlot.languageName}}</div>
                            <div class="p-formgroup">
                                <div class="p-field">
                                    <Textarea v-model="editLanguageValue" id="edit_lang" rows="4" cols="30" />
                                </div>
                                <Button type="submit" label="Update" class="btn-primary sup_submit" />
                            </div>
                        </form>
                    </div>
                    <div class="sup_language_view_area">
                        <div class="sup_language_name"><span class="sup_language_flag"><img :src="translationSlot.languageFlag" :alt="translationSlot.languageName"></span>{{translationSlot.languageName}}<div class="sup_language_updatedon"><strong>Updated On:</strong> {{usFormatDate(translationSlot.translatedUpdatedAt)}}</div></div>
                        <div class="sup_language_value">{{translationSlot.translatedText}}</div>
                    </div>
                </div>
                <div v-if="languageTextModals.updateTranslationStatus !== null" class="sup_form_massage p-mt-3 success">{{languageTextModals.updateTranslationStatus}}</div>
            </div>
        </Dialog>
        <!--- Edit Language Key Modal End --->
        <!--- Deactive/Restore Confirmation Start --->
        <Dialog header="Confirmation" v-model:visible="confirmationModal.modalState" :style="{width: '350px'}" :modal="true">
            <div class="confirmation-content p-d-flex p-items-center">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 1.6rem" />
                <span>Do you want to proceed?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="confirmationModal.modalState = false" class="p-button-text" autofocus/>
                <Button label="Yes" icon="pi pi-check" @click="deleteAndRestoreLanguageText(confirmationModal.modaldata.data, confirmationModal.modaldata.type), confirmationModal.modalState = false" class="p-button-primary" />
            </template>
        </Dialog>
        <!--- Deactive/Restore Confirmation End --->
    </div>
</template>

<script>
import { onBeforeMount, reactive, ref } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
import { allLanguageTextList, languageExport } from '../../../../config/appUrls';
import { apiKey } from "../../../../config/constant";
import userLogout from '../../../../helper/userLogout/userLogout';
import { formatDate, usFormatDate, capitalizeCase } from "../../../../helper/utility/utility";
const FileSaver = require('file-saver');

export default {
    name: "LanguageText",
    setup(){
        //Store call
        let store = useStore();

        const allLangageTextData = reactive({
            data: [],
            total: null,
            status: null,
            loading: false,
            activityStatus: 'active',
            search: null,
            dateRange: null
        });

        const languageTextModals = reactive({
            translation: false,
            translationData: '',
            updateTranslationStatus: null
        });

        let editLanguageValue = ref();

        let editLanguageState = ref();

        //Export Language type
        const exportType = reactive([
            {
                label: 'Excel',
                icon: 'pi pi-file-excel',
                command: () => {
                    exportXL('excel');
                }
            },
            {
                label: 'PDF',
                icon: 'pi pi-file-pdf',
                command: () => {
                    exportXL('pdf');
                }
            }
        ]);

        //Active-deactive confimation state
        const confirmationModal = reactive({
            modalState: false,
            modaldata: '',
        });

        //On before mount function invoke
        onBeforeMount(async()=>{
            fetchAllLanguageText();
        });

        //Fetch All Language Key
        const fetchAllLanguageText = async (activityStatus, load) =>{
            let date = null;
            if(load === "languageStatus"){
                allLangageTextData.activityStatus = activityStatus === 'active' ? 'de-active' : 'active';
            }

            if(load === "resetFilters"){
                allLangageTextData.search = null;
                allLangageTextData.dateRange = null;
            }

            if (load === 'dateRange') {
                date = formatDate(allLangageTextData.dateRange[0]) + ' ' + formatDate(allLangageTextData.dateRange[1]);
            }

            try{
                allLangageTextData.loading = !allLangageTextData.loading;
                const response = await axios.get(allLanguageTextList, {
                    params: {
                        status: allLangageTextData.activityStatus,
                        search: allLangageTextData.search,
                        filterDt: date
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                });
                let result = response.data.data.groups;
                let tempArr = [];

                for(let i=0; i < result.length; i++){
                    let obj = {
                        keyName: result[i].keyName,
                        createdAt: result[i].translated[0].outer.translatedCreatedAt,
                        deletedAt: result[i].translated[0].outer.translatedDeletedAt,
                        status: result[i].translated[0].outer.translatedStatus,
                        translated: result[i].translated
                    }
                    tempArr.push(obj)
                }
                allLangageTextData.data = tempArr;

                allLangageTextData.loading = !allLangageTextData.loading;
            }catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        const traslateLanguage = (slotData) => {
            let translationArr = [];
            for (let i = 0; i < slotData.translated.length; i++) {
                translationArr.push(slotData.translated[i].slots)
            }
            languageTextModals.translation = true;
            languageTextModals.translationData = translationArr;
        }

        const editlanguage = (index, value) => {
            editLanguageState.value = index === editLanguageState.value ? null : index;

            if(editLanguageState.value !== null){
                editLanguageValue.value = null;
                editLanguageValue.value = value;
            } else {
                editLanguageValue.value = null;
            }
        }

        const updateLanguageValue = async(uuid) => {

            try{
                const options = {
                    method: 'put',
                    url: allLanguageTextList,
                    params: {
                        uuid: uuid
                    },
                    data: JSON.stringify({
                        text: editLanguageValue.value,
                    }),
                    headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                    transformRequest: [(data) => {
                        // transform the data
                        return data;
                    }]
                };
                let response = await axios(options);
                languageTextModals.updateTranslationStatus = response.data.message;
                setTimeout(()=>{
                    languageTextModals.updateTranslationStatus = null;
                }, 3000);
                for(let i = 0; i < languageTextModals.translationData.length; i++){
                    if(languageTextModals.translationData[i].translateduuid === response.data.data.uuid){
                        languageTextModals.translationData[i].translatedText = response.data.data.languageText;
                    }
                }
            } catch(err){
                console.log('err', err);
            }
        }

        //Export Language list
        const exportXL = async (load) => {
            let date = null;
            if (load === 'excel') {
                try{
                    if (allLangageTextData.dateRange !== null) {
                        date = formatDate(allLangageTextData.dateRange[0]) + ' ' + formatDate(allLangageTextData.dateRange[1]);
                    }
                    const response = await axios.get( languageExport, {
                        responseType: 'arraybuffer',
                        params: {
                            exe: "xlsx",
                            print: false,
                            status: allLangageTextData.activityStatus,
                            search: allLangageTextData.search,
                            filterDt: date
                        },headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        },
                    });
                    if(response.status === 200) {
                        const dirtyFileName = response.headers['content-disposition'];
                        const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
                        var fileName = dirtyFileName.match(regex)[3];
                        var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        FileSaver(blob, fileName);
                    }
                } catch(err) {
                    console.log('error', err);
                }   
            }
        }

        //Delete-restore confirmation
        const confirmationModalfn = (sloteData, load) =>{
            confirmationModal.modalState = true;
            confirmationModal.modaldata = {
                data: sloteData,
                type: load
            }
        }

        //Delete-restore language HTTP call
        // const deleteAndRestoreLanguageText = async (slotData, methodType) => {
        //     try{
        //         const options = {
        //             method: methodType === 'delete' ? 'delete' : 'patch',
        //             url: allLanguageTextList,
        //             params: {
        //                 uuid: slotData.uuid
        //             },
        //             headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
        //             transformRequest: [(data) => {
        //                 // transform the data
        //                 return data;
        //             }]
        //         };
        //         await axios(options);
        //         fetchAllLanguageText('active');
        //     } catch(err){
        //         console.log(err);
        //     }
        // }

        return{
            exportType,
            allLangageTextData,
            languageTextModals,
            editLanguageValue,
            editLanguageState,
            confirmationModal,
            fetchAllLanguageText,
            usFormatDate,
            traslateLanguage,
            editlanguage,
            updateLanguageValue,
            capitalizeCase,
            exportXL,
            confirmationModalfn,
            // deleteAndRestoreLanguageText
        }

    }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/style/component/superAdmin/pages/widgetManagement/LanguageText'
</style>